"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preloaderFlex = exports.bgStyles = void 0;
var bgStyles = {
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  width: '100%',
  height: '100%',
  zIndex: '9999'
};
exports.bgStyles = bgStyles;
var preloaderFlex = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};
exports.preloaderFlex = preloaderFlex;
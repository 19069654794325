"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.periodChange = exports.minutesChange = exports.hoursChange = exports.timeChange = exports.getTime = void 0;

var _chromaAnalytics = require("./../../../../../@iag-packages/chroma-analytics");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var getTime = function getTime(value, timeType) {
  var startValue = timeType === 'hours' ? 0 : 2;
  return value.replace(/:/g, '').substr(startValue, 2);
};

exports.getTime = getTime;

var timeChange = function timeChange(value, callbacksObject, analyticsEvent) {
  var onHoursChange = callbacksObject.onHoursChange,
      onMinutesChange = callbacksObject.onMinutesChange;
  var hoursStr = getTime(value, 'hours');
  onHoursChange(hoursStr);

  var hoursEvent = _objectSpread(_objectSpread({}, analyticsEvent), {}, {
    tagName: _objectSpread(_objectSpread({}, analyticsEvent.tagName), {}, {
      name: 'Hours'
    }),
    value: hoursStr
  });

  var minutesStr = getTime(value, 'minutes');
  onMinutesChange(minutesStr);

  var minutesEvent = _objectSpread(_objectSpread({}, analyticsEvent), {}, {
    tagName: _objectSpread(_objectSpread({}, analyticsEvent.tagName), {}, {
      name: 'Minutes'
    }),
    value: minutesStr
  });

  if (hoursStr.length === 2 && minutesStr.length === 0) (0, _chromaAnalytics.createEventAndFire)(hoursEvent);
  if (minutesStr.length === 2) (0, _chromaAnalytics.createEventAndFire)(minutesEvent);
};

exports.timeChange = timeChange;

var hoursChange = function hoursChange(value, callback, analyticsEvent) {
  callback(value);
  (0, _chromaAnalytics.createEventAndFire)(analyticsEvent);
};

exports.hoursChange = hoursChange;

var minutesChange = function minutesChange(value, callback, analyticsEvent) {
  callback(value);
  (0, _chromaAnalytics.createEventAndFire)(analyticsEvent);
};

exports.minutesChange = minutesChange;

var periodChange = function periodChange(event, callback, analyticsEvent) {
  callback(event.target.value);
  (0, _chromaAnalytics.createEventAndFire)(analyticsEvent);
};

exports.periodChange = periodChange;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var normalizeToArray = function normalizeToArray(item) {
  return [].concat(item).filter(function (i) {
    return i !== null && i !== undefined;
  });
};

var _default = normalizeToArray;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var recursiveCloneChildren = function recursiveCloneChildren(children, additionalProps) {
  return _react.default.Children.map(children, function (child) {
    var childProps = {};

    if (_react.default.isValidElement(child)) {
      childProps = additionalProps;
    }

    if (child && child.props) {
      // String has no Prop
      childProps.children = recursiveCloneChildren(child.props.children, additionalProps);
      return _react.default.cloneElement(child, childProps);
    }

    return child;
  });
};

var _default = recursiveCloneChildren;
exports.default = _default;
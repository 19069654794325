"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var isValidDate = function isValidDate(dateStr) {
  var d = null;

  if (dateStr.includes('/')) {
    var datePieces = dateStr.split('/');
    var dFormatted = "".concat(datePieces[1], "/").concat(datePieces[0], "/").concat(datePieces[2]);
    d = new Date(dFormatted);
  } else {
    d = new Date(dateStr);
  }

  if (d.getTime()) return true;
  return false;
};

var _default = isValidDate;
exports.default = _default;
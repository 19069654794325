"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var inputSize;

var setInputSize = function setInputSize(size) {
  switch (size) {
    case 1:
      inputSize = 'c-input-width--1';
      break;

    case 2:
      inputSize = 'c-input-width--2';
      break;

    case 3:
      inputSize = 'c-input-width--3';
      break;

    case 4:
      inputSize = 'c-input-width--4';
      break;

    default:
      inputSize = '';
  }

  return inputSize;
};

var _default = {
  setInputSize: setInputSize
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function isMobile() {
  var ssr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  if (!ssr && window) {
    if (window.innerWidth < 769) {
      // chroma-core sm breakpoint
      return true;
    }
  }

  return false;
}

var _default = isMobile;
exports.default = _default;
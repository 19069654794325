import React from 'react'
import { Container, Image } from '../Chroma'
import './Swann.css'
import swannLogo from './img/swaninsurance.jpg'

//  import homePage from '../Lantern/images/swaninsurance.jpg'

const SwannHeader = () => (
    <Container>
        <div className="s4-notdlg">
            <div className="clear" />
            <div id="wrap">
                <div id="page_wrap">
                    <div id="header">
                        <div id="head_bar">
                            <p id="ph_number">
                                Talk to us 0800 807 926</p>
                            <input type="submit" id="search_submit" value="GO" />
                            <input type="text" id="search_input" value="" readOnly />
                        </div>
                        <div id="head_nav">
                            <div id="logo">
                                <Image src={swannLogo} alt="logo" />
                            </div>
                            <ul id="nav">
                                <li className="navItem hasMegaMenu"><a className="navLink" href="https://www.swanninsurance.co.nz/">Insurance</a></li>
                                <li className="navItem"><a className="navLink" href="https://www.swanninsurance.co.nz/">About Us</a></li>
                                <li className="navItem"><a className="navLink" href="https://www.swanninsurance.co.nz/">Get a Quote</a></li>
                                <li className="navItem"><a className="navLink" href="https://www.swanninsurance.co.nz/">Make a Claim</a></li>
                                <li className="navItem"><a className="navLink" href="https://www.swanninsurance.co.nz/">Community</a></li>
                                <li className="navItem"><a className="navLink" href="https://www.swanninsurance.co.nz/">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h1>Online Payment</h1>
    </Container>
)

export default SwannHeader
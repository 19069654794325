import React from "react";
import { Alert, Text } from "../../components/Chroma";
import { useHistory } from "react-router-dom";

const Success = (props) => {
  const history = useHistory();
  return (
    <Alert
      success
      heading="Payment Successful"
      closeButton={true}
      onClose={() => history.push("/pay")}
    >
      <Text>Thanks your payment has been successful </Text>
    </Alert>
  );
};

export default Success;

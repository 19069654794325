"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateBgColor = exports.generateTextColor = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var generateTextColor = function generateTextColor(color) {
  var colorClass = (0, _classnames.default)({
    'u-text--white': color === 'white',
    'u-text--black': color === 'black',
    'u-text--grey-darken-50': color === 'grey',
    'u-text--green': color === 'green',
    'u-text--blue': color === 'blue',
    'u-text--orange': color === 'orange',
    'u-text--red': color === 'red',
    'color--action': color === 'action',
    'u-text--primary': color === 'primary',
    'u-text--secondary': color === 'secondary'
  });
  return colorClass || '';
};

exports.generateTextColor = generateTextColor;
var bgColorClass;

var generateBgColor = function generateBgColor(bgColor) {
  switch (bgColor) {
    case 'primary-lighten':
      bgColorClass = 'bg-primary--lighten-90';
      break;

    case 'secondary-lighten':
      bgColorClass = 'bg-secondary--lighten-90';
      break;

    case 'blue-lighten':
      bgColorClass = 'bg-blue--lighten-80';
      break;

    default:
      bgColorClass = "bg-".concat(bgColor);
  }

  return bgColorClass;
};

exports.generateBgColor = generateBgColor;
import React from "react";
import { Container, Image } from "../Chroma";
import "./css/master.css";
import homePage from './images/HomePage.jpg'

const LanternHeader = () => (
    <Container>
        <div>
            <ul id="mainNav">
                <div id="zz1_TopNavigationMenuV4" >
                    <div>
                        <ul >
                            <li ><a title="Home" href="https://www.lanterninsurance.co.nz/" >Home </a></li>
                            <li ><a title="Home" href="https://www.lanterninsurance.co.nz/" >Contents </a></li>
                            <li ><a title="Home" href="https://www.lanterninsurance.co.nz/" >Car </a></li>
                        </ul>
                    </div>
                </div>
            </ul>
            <div id="navWrapper">

                <ul id="secNav">
                    <li><a id="A1" href="https://www.lanterninsurance.co.nz/">About us</a></li>
                    <li><a id="A2" href="https://www.lanterninsurance.co.nz/">Make a claim</a></li>
                    <li><a id="A3" href="https://www.lanterninsurance.co.nz/">Help</a></li>
                    <li><a id="A4" href="https://www.lanterninsurance.co.nz/">Contact us</a></li>
                </ul>
            </div>
        </div>
        <Image src={homePage} alt="Happy women with dog on sofa" />
        <h1>Online Payment</h1>
    </Container>
);

export default LanternHeader;

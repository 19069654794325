import React, { Fragment } from 'react';
import PayPolicy from './payment/PayPolicy';
import SwannHeader from '../components/Swann/SwannHeader';
import SwannFooter from '../components/Swann/SwannFooter';
import LanternHeader from '../components/Lantern/LanternHeader';
import LanternFooter from '../components/Lantern/LanternFooter';
import * as brandHelper from '../lib/api/utils/brandHelper';

const HomePage = (props) => {
  const swannBrand = props.brand === brandHelper.Brands.swann;
  let header = swannBrand ? <SwannHeader /> : <LanternHeader />;
  let footer = swannBrand ? <SwannFooter /> : <LanternFooter />;
  return (
    <div>
      {header}
      <PayPolicy isSwann={swannBrand} brand={props.brand} />
      {footer}
    </div>
  );
};

export default HomePage;

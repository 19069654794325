"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFavicon = exports.getFontsPath = exports.getAssetsPath = exports.getCssUrl = exports.getAssetUrl = exports.getBucketUrl = exports.getAssetVersion = exports.getChromaCoreVersion = void 0;

var _package = require("../../../package.json");

var BUCKET_PATH = 'https://tags.iag.com.au/chroma/';

var getChromaCoreVersion = function getChromaCoreVersion() {
  return _package.chromaCoreDependency;
};

exports.getChromaCoreVersion = getChromaCoreVersion;

var getAssetVersion = function getAssetVersion() {
  return _package.chromaAssetsDependency;
};

exports.getAssetVersion = getAssetVersion;

var getBucketName = function getBucketName() {
  return 'chroma-core-s3';
};

var getBucketUrl = function getBucketUrl() {
  return "".concat(BUCKET_PATH).concat(getBucketName(), "/v").concat(getChromaCoreVersion());
};

exports.getBucketUrl = getBucketUrl;

var getAssetUrl = function getAssetUrl() {
  return "".concat(BUCKET_PATH, "chroma-assets/v").concat(getAssetVersion());
};

exports.getAssetUrl = getAssetUrl;

var getCssUrl = function getCssUrl(brand) {
  return "".concat(getBucketUrl(), "/css/theme/").concat(brand, "/main.css.gz");
};

exports.getCssUrl = getCssUrl;

var getAssetsPath = function getAssetsPath() {
  return "".concat(getAssetUrl(), "/assets");
};

exports.getAssetsPath = getAssetsPath;

var getFontsPath = function getFontsPath() {
  return "".concat(getAssetUrl(), "/css/fonts");
};

exports.getFontsPath = getFontsPath;

var getFavicon = function getFavicon(brand) {
  return "".concat(getAssetsPath(), "/favicons/").concat(brand, "/favicon.ico");
};

exports.getFavicon = getFavicon;
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getCssUrl, getFavicon, ChromaContext } from './components/Chroma';
import { useLocation } from 'react-router-dom';
import Routes from './pages/Routes';
import * as brandHelper from './lib/api/utils/brandHelper';

const App = () => {
    const query = new URLSearchParams(useLocation().search);
    const { brand, setBrand } = useContext(ChromaContext);
    const appVersion = require('../package.json').version;

    useEffect(() => {
        const queryBrand = brandHelper.getBrand(query);
        setBrand(queryBrand);
    }, [brand]);

    return (
        <Fragment>
            <Helmet>
                <link href={getCssUrl(brand)} rel="stylesheet" />
                <link href={getFavicon(brand)} rel="icon" />
                <meta name="description" content={`product v${appVersion}`} />
            </Helmet>
            <Routes brand={brand} />
        </Fragment>
    );
};

export default App;

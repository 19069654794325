"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disablePageScrollingWithArrowKeys = exports.enablePageScrollingWithArrowKeys = exports.preventArrowKeysDefaultBehaviour = void 0;

var preventArrowKeysDefaultBehaviour = function preventArrowKeysDefaultBehaviour(e) {
  if ([38, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
};

exports.preventArrowKeysDefaultBehaviour = preventArrowKeysDefaultBehaviour;

var enablePageScrollingWithArrowKeys = function enablePageScrollingWithArrowKeys() {
  window.removeEventListener('keydown', preventArrowKeysDefaultBehaviour);
};

exports.enablePageScrollingWithArrowKeys = enablePageScrollingWithArrowKeys;

var disablePageScrollingWithArrowKeys = function disablePageScrollingWithArrowKeys() {
  window.addEventListener('keydown', preventArrowKeysDefaultBehaviour);
};

exports.disablePageScrollingWithArrowKeys = disablePageScrollingWithArrowKeys;
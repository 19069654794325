import React from 'react'
import { Container } from '../Chroma'
import './Swann.css'

const SwannFooter = () => (
<Container>
<div id="footer_wrap">
                <div id="footer_content">
                    <div className="fourCol">
                        <div className="oneCol">
                            <p className="title">
                                Insurance Solutions</p>
                            <ul>
                                <li><a href="https://www.swanninsurance.co.nz/">Classic Car</a></li>
                                <li><a href="https://www.swanninsurance.co.nz/">Classic Motorcycle</a></li>
                                <li><a href="https://www.swanninsurance.co.nz/">Modern Classic Car</a></li>
                                <li><a href="https://www.swanninsurance.co.nz/">Motorcycles and Scooters</a></li>
                            </ul>
                        </div>
                     
                        <hr className="clear" />
                    </div>
                    <div id="legal">
                        <p>
                        © IAG New Zealand Limited <a href="https://www.swanninsurance.co.nz/">Privacy Policy</a> <a href="https://www.swanninsurance.co.nz/">Terms of Use</a>
                        </p>
                        <p>
                            Swann Insurance is a business division of IAG New Zealand Ltd</p>
                    </div>
                </div>
            </div>
</Container>
)

export default SwannFooter
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var getClassName = function getClassName(utility, item) {
  var breakpoint = item[0];
  var value = item[1];
  return "".concat(utility, "-").concat(breakpoint, "--").concat(value);
};

var setUtilityClass = function setUtilityClass(utility, value) {
  if (_typeof(value) === 'object' && value !== null) {
    return Object.entries(value).map(function (item) {
      return getClassName(utility, item);
    }).join(' ');
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return "".concat(utility).concat(value ? "--".concat(value) : '');
  }

  return utility || '';
};

var _default = setUtilityClass;
exports.default = _default;
import React from "react";

const Payment = (props) => {
  return (
    <div>    
      <iframe
        id="windcave"
        name="iFramewindave"
        src={props.src}
        width="100%"
        title="dps"
        height={900}
      ></iframe>
    </div>
  );
};

export default Payment;

import React from "react";
import {
    FormGroup,
    Input,
  } from "../../components/Chroma";

  const RegistrationNumber = (props) => {
      return (
        <FormGroup
        id="registrationNumber"
        label="Reg#"
        error={props.registrationNumberError}
      >
        <Input
          error={props.registrationNumberError !== ""}
          value={props.registrationNumber}
          maxLength={6}
          onChange={props.registrationNumberChange}
        />
      </FormGroup>
      )
  }

  export default RegistrationNumber;
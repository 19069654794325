import React, {useState} from 'react'
import { Route, Switch } from 'react-router-dom'
import HomePage from './HomePage'
import Success from './payment/Success'
import SuccessRedirect from './payment/SuccessRedirect'
import Failure from './payment/Failure'
import FailureRedirect from './payment/FailureRedirect'
import CreditCard from '../pages/payment/CreditCard'

const Routes = props => {
  const { brand } = props /* eslint-disable-line */
  return (
    <Switch>
      <Route path="/pay" >
        <HomePage brand={brand}/>
      </Route>
      <Route path="/success" brand={brand} exact component={Success} />
      <Route path="/failure" brand={brand} exact component={Failure } />
      <Route path="/creditcard"> <CreditCard brand={brand} /> </Route>
      <Route path="/success-redirect" brand={brand} exact component={SuccessRedirect} />
      <Route path="/failure-redirect" brand={brand} exact component={FailureRedirect} />
    </Switch>
  )
}
export default Routes

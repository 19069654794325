import React from "react";
import { Container } from "../Chroma";
import "./css/master.css";

const LanternFooter = () => (
    <Container>
        <div className="s4-notdlg">
            <div id="footer">
                <div id="footerWrapper">
                    <div className="triCol marginRight6 blueDotsBg fixedHeight">
                        <ul className="footerLinks marginRight6">
                            <li>
                                <h5>
                                    <a
                                        id="A5"
                                        href="https://www.lanterninsurance.co.nz/"
                                    >
                                        Car
                                    </a>
                                </h5>
                            </li>
                        </ul>
                        <ul className="footerLinks last">
                            <li>
                                <h5>
                                    <a
                                        id="A6"
                                        href="https://www.lanterninsurance.co.nz/"
                                    >
                                        Home
                                    </a>
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="triCol marginRight6 blueDotsBg fixedHeight">
                        <ul className="footerLinks marginRight6">
                            <li>
                                <h5>
                                    <a
                                        id="A7"
                                        href="https://www.lanterninsurance.co.nz/"
                                    >
                                        Contents
                                    </a>
                                </h5>
                            </li>
                        </ul>
                        <ul className="footerLinks last">
                            <li>
                                <h5>Other</h5>
                            </li>
                            <li>
                                <a href="https://www.lanterninsurance.co.nz/">
                                    Travel
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.lanterninsurance.co.nz/"
                                >
                                    Small Business
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="triCol last noBg fixedHeight">
                        <ul className="footerLinks marginRight6">
                            <li>
                                <h5>Tools</h5>
                            </li>
                            <li>
                                <a
                                    href="https://www.lanterninsurance.co.nz/"
                                >
                                    Make a claim
                                </a>
                            </li>
                        </ul>
                        <ul className="footerLinks last">
                            <li>
                                <h5>
                                    <a href="https://www.lanterninsurance.co.nz/">
                                        About us
                                    </a>
                                </h5>
                            </li>
                            <li>
                                <a
                                    href="https://www.lanterninsurance.co.nz/"
                                >
                                    Contact us
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.lanterninsurance.co.nz/"
                                >
                                    FAQs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="legalFooterBlock">
                <p>
                    &copy; IAG New Zealand Limited 2011{" "}
                    <a
                        href="https://www.lanterninsurance.co.nz/"
                    >
                        Privacy Policy
                    </a>{" "}
                    <a
                        href="https://www.lanterninsurance.co.nz/"
                    >
                        Terms of Use
                    </a>
                </p>
                <p className="secondLine">
                    Lantern Insurance is a business division of IAG New Zealand Ltd
                </p>
            </div>
        </div>
    </Container>
);

export default LanternFooter;

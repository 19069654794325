"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toKebabCase = exports.randomString = exports.alphaNumericString = void 0;

var randomString = function randomString(length) {
  var result = '';
  var chars = '123456789abcdefghijklmnopqrstuvwxyz';

  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
};

exports.randomString = randomString;

var toKebabCase = function toKebabCase(str) {
  return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(function (x) {
    return x.toLowerCase();
  }).join('-');
};

exports.toKebabCase = toKebabCase;

var alphaNumericString = function alphaNumericString(str) {
  return str.replace(/[^a-z0-9]/gi, '');
};

exports.alphaNumericString = alphaNumericString;
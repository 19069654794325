import React, { Fragment } from 'react';


const FailureRedirect = (props) => {
  window.top.postMessage('FailedPayment', window.location.origin);
  return (
    <Fragment>
    </Fragment>
  )
};

export default FailureRedirect;

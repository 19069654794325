"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var getClassName = function getClassName(prefix, item, limit) {
  var breakpoint = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  if (limit && limit.length > 0 && !limit.includes(item[0])) {
    return '';
  }

  var direction = '';
  var value = '';

  if (_typeof(item) === 'object') {
    direction = item[0].substr(0, 1);
    value = item[1];
    /* eslint-disable-line */

    return "".concat(prefix, "-").concat(direction).concat(breakpoint !== '' ? "-".concat(breakpoint) : '', "--").concat(value);
  }

  return "".concat(prefix, "-").concat(breakpoint, "--").concat(item);
};

var breakpointsArr = ['xs', 'sm', 'md', 'lg'];

var convertObjectToClassName = function convertObjectToClassName(prefix, obj, limit) {
  if (_typeof(obj) === 'object' && Object.entries(obj).length !== 0) {
    return Object.entries(obj).map(function (item) {
      // if breakpoint uses shortcut eg. xs="4"
      if (breakpointsArr.includes(item[0]) && _typeof(item[1]) !== 'object') {
        return getClassName(prefix, item[1], limit, item[0]);
      } // eg. { top: 1, xs: { bottom: 3 } }


      if (_typeof(item[1]) === 'object') {
        return Object.entries(item[1]).map(function (subItem) {
          return getClassName(prefix, subItem, limit, item[0]);
        }).join(' ');
      } // eg. { top: 3, bottom: 3, right: 2 }


      return getClassName(prefix, item, limit);
    }).join(' ');
  }

  if (typeof obj === 'number') {
    return "".concat(prefix, "--").concat(obj);
  }

  return '';
};

var _default = convertObjectToClassName;
exports.default = _default;
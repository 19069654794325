import { v4 as uuidv4 } from "uuid";
import * as apihelper from "./utils/apiHelper";

export async function getUrl(
  clientName,
  amount,
  clientNumber,
  policyNumber,
  registrationNumber,
  sucessUrl,
  failUrl,
  brand
) {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-Session-ID", uuidv4());
  headers.append("X-Correlation-ID", uuidv4());
  headers.append("X-BRAND", brand);

  var jsonBody = JSON.stringify({
    clientName: clientName,
    amount: Number(amount),
    clientNumber: clientNumber,
    policyNumber: policyNumber,
    registrationNumber: registrationNumber,
    externalSuccessURL: `${apihelper.getBaseUri()}${sucessUrl}?brand=${brand}`,
    externalFailureURL: `${apihelper.getBaseUri()}${failUrl}?brand=${brand}`,
  });

  var requestOptions = {
    method: "POST",
    headers: headers,
    body: jsonBody,
    redirect: "follow",
  };

  const response = await fetch(
    `${apihelper.getBaseUri()}/api/policy/pay`,
    requestOptions
  );

    //Range outside of 200-299
    if (!response.ok) {
        throw new Error(response.text());
    }

  const data = await response.text();

  return data;
}


"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateFontWeightClass = exports.generateFontSizeClass = exports.generateFontSizes = void 0;

var generateFontSizes = function generateFontSizes() {
  var from = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 12;
  var to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
  var fontSizesArray = [];

  for (var i = from; i <= to; i += 2) {
    fontSizesArray.push(i);
  }

  return fontSizesArray;
};

exports.generateFontSizes = generateFontSizes;

var generateFontSizeClass = function generateFontSizeClass(size) {
  var from = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 12;
  var to = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50;
  var availableSizes = generateFontSizes(from, to);
  var checkSize = availableSizes.find(function (el) {
    return el === size;
  });
  return checkSize !== undefined ? "u-font--".concat(checkSize) : '';
};

exports.generateFontSizeClass = generateFontSizeClass;

var generateFontWeightClass = function generateFontWeightClass(weight) {
  var availableWeights = [300, 400, 500, 600, 700];
  var checkWeight = availableWeights.find(function (el) {
    return el === weight;
  });
  return checkWeight !== undefined ? "u-weight--".concat(checkWeight) : '';
};

exports.generateFontWeightClass = generateFontWeightClass;
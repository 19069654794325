import React from "react";
import {
    Column,
    FormGroup,
    Input,
    Row,
    Select,
  } from "../../components/Chroma";

  import "./Swann.css"

const PolicyNumber = props => {
    return(
        <Row>
        <Column  sm={6}>
              <FormGroup
                id="policyNumber"
                label="Policy Number*"
                error={props.policyNumberError}
                margin={{ bottom: 0 }}
              >
                <Input
                  error={props.policyNumberError !== ""}
                  value={props.policyNumber}
                  onChange={props.policyNumberChange}
                />
              </FormGroup>
            </Column>

             <Column  className="selectHeight">
                <Select 
                  size={2} value={props.policyNumberSuffix}
                  values={[
                    {
                      label: "-vcy",
                      value: "-vcy",
                    },
                    {
                      label: "-swn",
                      value: "-swn",
                    },
                  ]}
                  onChange={props.policyNumberSuffixChange}
                />
            </Column> 
            </Row>
    );
};

export {PolicyNumber};
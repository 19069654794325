"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var CORE_ICONS = {
  addressBook: 'addressBook',
  addressCard: 'addressCard',
  angleDown: 'angleDown',
  angleLeft: 'angleLeft',
  angleRight: 'angleRight',
  angleUp: 'angleUp',
  arrowDown: 'arrowDown',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  arrowToBottom: 'arrowToBottom',
  arrowToTop: 'arrowToTop',
  arrowUp: 'arrowUp',
  arrowsAltH: 'arrowsAltH',
  arrowsAltV: 'arrowsAltV',
  arrowsAlt: 'arrowsAlt',
  ban: 'ban',
  bars: 'bars',
  briefcase: 'briefcase',
  calculator: 'calculator',
  calendarAlt: 'calendarAlt',
  calendarCheck: 'calendarCheck',
  calendarExclamation: 'calendarExclamation',
  calendarTimes: 'calendarTimes',
  caretDown: 'caretDown',
  caretLeft: 'caretLeft',
  caretRight: 'caretRight',
  caretUp: 'caretUp',
  ccAmex: 'ccAmex',
  ccMastercard: 'ccMastercard',
  ccPaypal: 'ccPaypal',
  ccVisa: 'ccVisa',
  chartBar: 'chartBar',
  checkCircle: 'checkCircle',
  check: 'check',
  chevronDown: 'chevronDown',
  chevronLeft: 'chevronLeft',
  chevronRight: 'chevronRight',
  chevronUp: 'chevronUp',
  clipboardList: 'clipboardList',
  clock: 'clock',
  cog: 'cog',
  commentAlt: 'commentAlt',
  comment: 'comment',
  commentsAlt: 'commentsAlt',
  comments: 'comments',
  compressAlt: 'compressAlt',
  copy: 'copy',
  creditCard: 'creditCard',
  desktop: 'desktop',
  ellipsisV: 'ellipsisV',
  exclamationTriangle: 'exclamationTriangle',
  exclamation: 'exclamation',
  expandAlt: 'expandAlt',
  expandArrowsAlt: 'expandArrowsAlt',
  externalAlt: 'externalAlt',
  eyeSlash: 'eyeSlash',
  eye: 'eye',
  facebook: 'facebook',
  facebookMessenger: 'facebookMessenger',
  fileAlt: 'fileAlt',
  fileCertificate: 'fileCertificate',
  filePdf: 'filePdf',
  file: 'file',
  filter: 'filter',
  folderOpen: 'folderOpen',
  frown: 'frown',
  googlePlus: 'googlePlus',
  heart: 'heart',
  home: 'home',
  image: 'image',
  infoCircle: 'infoCircle',
  info: 'info',
  instagram: 'instagram',
  laptop: 'laptop',
  link: 'link',
  linkedIn: 'linkedIn',
  locationArrow: 'locationArrow',
  lockOpen: 'lockOpen',
  lock: 'lock',
  mapMarkerAlt: 'mapMarkerAlt',
  meh: 'meh',
  minusCircle: 'minusCircle',
  minus: 'minus',
  mobileAlt: 'mobileAlt',
  paperclip: 'paperclip',
  pen: 'pen',
  phone: 'phone',
  plusCircle: 'plusCircle',
  plus: 'plus',
  print: 'print',
  questionCircle: 'questionCircle',
  question: 'question',
  receipt: 'receipt',
  redo: 'redo',
  searchMinus: 'searchMinus',
  searchPlus: 'searchPlus',
  search: 'search',
  shareAlt: 'shareAlt',
  shoppingCart: 'shoppingCart',
  signIn: 'signIn',
  signOut: 'signOut',
  slidersH: 'slidersH',
  smile: 'smile',
  sortDown: 'sortDown',
  sortUp: 'sortUp',
  sort: 'sort',
  star: 'star',
  sync: 'sync',
  tabletAlt: 'tabletAlt',
  tag: 'tag',
  tasks: 'tasks',
  thLarge: 'thLarge',
  thList: 'thList',
  timesCircle: 'timesCircle',
  times: 'times',
  trashAlt: 'trashAlt',
  twitter: 'twitter',
  undo: 'undo',
  userCircle: 'userCircle',
  userPlus: 'userPlus',
  userTimes: 'userTimes',
  user: 'user',
  users: 'users',
  youTube: 'youTube',
  bookOpen: 'bookOpen',
  boxOpen: 'boxOpen',
  building: 'building',
  car: 'car',
  cctv: 'cctv',
  chair: 'chair',
  couch: 'couch',
  doorClosed: 'doorClosed',
  fragile: 'fragile',
  handHoldingUsd: 'handHoldingUsd',
  leaf: 'leaf',
  lightBulbOn: 'lightBulbOn',
  moneyCheckAlt: 'moneyCheckAlt',
  sink: 'sink',
  tools: 'tools',
  wineBottle: 'wineBottle',
  wrench: 'wrench',
  assistiveListeningSystems: 'assistiveListeningSystems',
  campground: 'campground',
  coin: 'coin',
  creditCardFront: 'creditCardFront',
  fileContract: 'fileContract',
  fileSignature: 'fileSignature',
  gem: 'gem',
  mp3Player: 'mp3Player',
  palette: 'palette',
  shoppingBag: 'shoppingBag',
  university: 'university'
};
var _default = CORE_ICONS;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

var _iconCoreList = _interopRequireDefault(require("../../assets/icons/iconCoreList"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var setIconClass = function setIconClass(icon) {
  var iconClass = (0, _classnames.default)({
    'i-address-book': icon === _iconCoreList.default.addressBook,
    'i-address-card': icon === _iconCoreList.default.addressCard,
    'i-angle-down': icon === _iconCoreList.default.angleDown,
    'i-angle-left': icon === _iconCoreList.default.angleLeft,
    'i-angle-right': icon === _iconCoreList.default.angleRight,
    'i-angle-up': icon === _iconCoreList.default.angleUp,
    'i-arrow-down': icon === _iconCoreList.default.arrowDown,
    'i-arrow-left': icon === _iconCoreList.default.arrowLeft,
    'i-arrow-right': icon === _iconCoreList.default.arrowRight,
    'i-arrow-to-bottom': icon === _iconCoreList.default.arrowToBottom,
    'i-arrow-to-top': icon === _iconCoreList.default.arrowToTop,
    'i-arrow-up': icon === _iconCoreList.default.arrowUp,
    'i-arrows-alt-h': icon === _iconCoreList.default.arrowsAltH,
    'i-arrows-alt-v': icon === _iconCoreList.default.arrowsAltV,
    'i-arrows-alt': icon === _iconCoreList.default.arrowsAlt,
    'i-ban': icon === _iconCoreList.default.ban,
    'i-bars': icon === _iconCoreList.default.bars,
    'i-briefcase': icon === _iconCoreList.default.briefcase,
    'i-calculator': icon === _iconCoreList.default.calculator,
    'i-calendar-alt': icon === _iconCoreList.default.calendarAlt,
    'i-calendar-check': icon === _iconCoreList.default.calendarCheck,
    'i-calendar-exclamation': icon === _iconCoreList.default.calendarExclamation,
    'i-calendar-times': icon === _iconCoreList.default.calendarTimes,
    'i-caret-down': icon === _iconCoreList.default.caretDown,
    'i-caret-left': icon === _iconCoreList.default.caretLeft,
    'i-caret-right': icon === _iconCoreList.default.caretRight,
    'i-caret-up': icon === _iconCoreList.default.caretUp,
    'i-cc-amex': icon === _iconCoreList.default.ccAmex,
    'i-cc-mastercard': icon === _iconCoreList.default.ccMastercard,
    'i-cc-paypal': icon === _iconCoreList.default.ccPaypal,
    'i-cc-visa': icon === _iconCoreList.default.ccVisa,
    'i-chart-bar': icon === _iconCoreList.default.chartBar,
    'i-check-circle': icon === _iconCoreList.default.checkCircle,
    'i-check': icon === _iconCoreList.default.check,
    'i-chevron-down': icon === _iconCoreList.default.chevronDown,
    'i-chevron-left': icon === _iconCoreList.default.chevronLeft,
    'i-chevron-right': icon === _iconCoreList.default.chevronRight,
    'i-chevron-up': icon === _iconCoreList.default.chevronUp,
    'i-clipboard-list': icon === _iconCoreList.default.clipboardList,
    'i-clock': icon === _iconCoreList.default.clock,
    'i-cog': icon === _iconCoreList.default.cog,
    'i-comment-alt': icon === _iconCoreList.default.commentAlt,
    'i-comment': icon === _iconCoreList.default.comment,
    'i-comments-alt': icon === _iconCoreList.default.commentsAlt,
    'i-comments': icon === _iconCoreList.default.comments,
    'i-compress-alt': icon === _iconCoreList.default.compressAlt,
    'i-copy': icon === _iconCoreList.default.copy,
    'i-credit-card': icon === _iconCoreList.default.creditCard,
    'i-desktop': icon === _iconCoreList.default.desktop,
    'i-ellipsis-v': icon === _iconCoreList.default.ellipsisV,
    'i-envelope': icon === _iconCoreList.default.envelope,
    'i-exclamation-triangle': icon === _iconCoreList.default.exclamationTriangle,
    'i-exclamation': icon === _iconCoreList.default.exclamation,
    'i-expand-alt': icon === _iconCoreList.default.expandAlt,
    'i-expand-arrows-alt': icon === _iconCoreList.default.expandArrowsAlt,
    'i-external-link': icon === _iconCoreList.default.externalAlt,
    'i-eye-slash': icon === _iconCoreList.default.eyeSlash,
    'i-eye': icon === _iconCoreList.default.eye,
    'i-facebook': icon === _iconCoreList.default.facebook,
    'i-facebook-messenger': icon === _iconCoreList.default.facebookMessenger,
    'i-file-alt': icon === _iconCoreList.default.fileAlt,
    'i-file-certificate': icon === _iconCoreList.default.fileCertificate,
    'i-file-pdf': icon === _iconCoreList.default.filePdf,
    'i-file': icon === _iconCoreList.default.file,
    'i-filter': icon === _iconCoreList.default.filter,
    'i-folder-open': icon === _iconCoreList.default.folderOpen,
    'i-frown': icon === _iconCoreList.default.frown,
    'i-google-plus-g': icon === _iconCoreList.default.googlePlus,
    'i-heart': icon === _iconCoreList.default.heart,
    'i-home': icon === _iconCoreList.default.home,
    'i-image': icon === _iconCoreList.default.image,
    'i-info-circle': icon === _iconCoreList.default.infoCircle,
    'i-info': icon === _iconCoreList.default.info,
    'i-instagram': icon === _iconCoreList.default.instagram,
    'i-laptop': icon === _iconCoreList.default.laptop,
    'i-link': icon === _iconCoreList.default.link,
    'i-linkedin-in': icon === _iconCoreList.default.linkedIn,
    'i-location-arrow': icon === _iconCoreList.default.locationArrow,
    'i-lock-open': icon === _iconCoreList.default.lockOpen,
    'i-lock': icon === _iconCoreList.default.lock,
    'i-map-marker-alt': icon === _iconCoreList.default.mapMarkerAlt,
    'i-meh': icon === _iconCoreList.default.meh,
    'i-minus-circle': icon === _iconCoreList.default.minusCircle,
    'i-minus': icon === _iconCoreList.default.minus,
    'i-mobile-alt': icon === _iconCoreList.default.mobileAlt,
    'i-paperclip': icon === _iconCoreList.default.paperclip,
    'i-pen': icon === _iconCoreList.default.pen,
    'i-phone': icon === _iconCoreList.default.phone,
    'i-plus-circle': icon === _iconCoreList.default.plusCircle,
    'i-plus': icon === _iconCoreList.default.plus,
    'i-print': icon === _iconCoreList.default.print,
    'i-question-circle': icon === _iconCoreList.default.questionCircle,
    'i-question': icon === _iconCoreList.default.question,
    'i-receipt': icon === _iconCoreList.default.receipt,
    'i-redo': icon === _iconCoreList.default.redo,
    'i-search-minus': icon === _iconCoreList.default.searchMinus,
    'i-search-plus': icon === _iconCoreList.default.searchPlus,
    'i-search': icon === _iconCoreList.default.search,
    'i-share-alt': icon === _iconCoreList.default.shareAlt,
    'i-shopping-cart': icon === _iconCoreList.default.shoppingCart,
    'i-sign-in': icon === _iconCoreList.default.signIn,
    'i-sign-out': icon === _iconCoreList.default.signOut,
    'i-sliders-h': icon === _iconCoreList.default.slidersH,
    'i-smile': icon === _iconCoreList.default.smile,
    'i-sort-down': icon === _iconCoreList.default.sortDown,
    'i-sort-up': icon === _iconCoreList.default.sortUp,
    'i-sort': icon === _iconCoreList.default.sort,
    'i-star': icon === _iconCoreList.default.star,
    'i-sync': icon === _iconCoreList.default.sync,
    'i-tablet-alt': icon === _iconCoreList.default.tabletAlt,
    'i-tag': icon === _iconCoreList.default.tag,
    'i-tasks': icon === _iconCoreList.default.tasks,
    'i-th-large': icon === _iconCoreList.default.thLarge,
    'i-th-list': icon === _iconCoreList.default.thList,
    'i-times-circle': icon === _iconCoreList.default.timesCircle,
    'i-times': icon === _iconCoreList.default.times,
    'i-trash-alt': icon === _iconCoreList.default.trashAlt,
    'i-twitter': icon === _iconCoreList.default.twitter,
    'i-undo': icon === _iconCoreList.default.undo,
    'i-user-circle': icon === _iconCoreList.default.userCircle,
    'i-user-plus': icon === _iconCoreList.default.userPlus,
    'i-user-times': icon === _iconCoreList.default.userTimes,
    'i-user': icon === _iconCoreList.default.user,
    'i-users': icon === _iconCoreList.default.users,
    'i-youtube': icon === _iconCoreList.default.youTube,
    'i-book-open': icon === _iconCoreList.default.bookOpen,
    'i-box-open': icon === _iconCoreList.default.boxOpen,
    'i-building': icon === _iconCoreList.default.building,
    'i-car': icon === _iconCoreList.default.car,
    'i-cctv': icon === _iconCoreList.default.cctv,
    'i-chair': icon === _iconCoreList.default.chair,
    'i-couch': icon === _iconCoreList.default.couch,
    'i-door-closed': icon === _iconCoreList.default.doorClosed,
    'i-fragile': icon === _iconCoreList.default.fragile,
    'i-hand-holding-usd': icon === _iconCoreList.default.handHoldingUsd,
    'i-leaf': icon === _iconCoreList.default.leaf,
    'i-lightbulb-on': icon === _iconCoreList.default.lightBulbOn,
    'i-money-check-alt': icon === _iconCoreList.default.moneyCheckAlt,
    'i-sink': icon === _iconCoreList.default.sink,
    'i-tools': icon === _iconCoreList.default.tools,
    'i-wine-bottle': icon === _iconCoreList.default.wineBottle,
    'i-wrench': icon === _iconCoreList.default.wrench,
    'i-assistive-listening-systems': icon === _iconCoreList.default.assistiveListeningSystems,
    'i-campground': icon === _iconCoreList.default.campground,
    'i-coin': icon === _iconCoreList.default.coin,
    'i-credit-card-front': icon === _iconCoreList.default.creditCardFront,
    'i-file-contract': icon === _iconCoreList.default.fileContract,
    'i-file-signature': icon === _iconCoreList.default.fileSignature,
    'i-gem': icon === _iconCoreList.default.gem,
    'i-mp3-player': icon === _iconCoreList.default.mp3Player,
    'i-palette': icon === _iconCoreList.default.palette,
    'i-shopping-bag': icon === _iconCoreList.default.shoppingBag,
    'i-university': icon === _iconCoreList.default.university
  });
  return iconClass || '';
};

var _default = setIconClass;
exports.default = _default;
'use strict';

let AnalyticsObject = { };

export function init(initObj) {
    AnalyticsObject = {
        ...initObj,
        event: {}
    };
}

export function createEventAndFire(eventObject) {
    AnalyticsObject.event = eventObject;
    fireEvents();
}

export function fireEvents() {
    // console.log('fireEvents', AnalyticsObject)
    if (AnalyticsObject.chromaTracking) {
        AnalyticsObject.chromaTracking(exports.AnalyticsObject);
        resetAnalyticsObject();
    }

}

export function resetAnalyticsObject() {
    AnalyticsObject = {
        ...AnalyticsObject,
        event: {}
    };
}
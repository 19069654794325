import React from "react";
import { FormGroup, Input } from "../../components/Chroma";

const PolicyNumber = (props) => {
  return (
    <FormGroup
      id="policyNumber"
      label="Policy Number"
      error={props.policyNumberError}
      margin={{ bottom: 0 }}
    >
      <Input
        error={props.policyNumberError !== ""}
        value={props.policyNumber}
        onChange={(e) => props.policyNumberChange(e)}
      />
    </FormGroup>
  );
};

export {PolicyNumber};

import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import  * as Analytics  from "./../../@iag-packages/chroma-analytics/dist/chroma-analytics";
import {
    Button,
    ButtonGroup,
    ChromaContext,
    Column,
    FormGroup,
    Input,
    Row,
    Main,
    Text,
    Alert,
} from "../../components/Chroma";
import "./PayPolicy.css";
import RegistrationNumber from "../../components/Swann/RegistrationNumber";
import { PolicyNumber as SwannPolicyNumber } from "../../components/Swann/PolicyNumber";
import { PolicyNumber as LanternPolicyNumber } from "../../components/Lantern/PolicyNumber";
import { useHistory, useLocation, Redirect, Route } from "react-router-dom";
import * as paymentApi from "../../lib/api/paymentApi";
import Container from "./../../@iag-packages/chroma-react/lib/layouts/grid/Container";

const RECUPTURE_SITE_KEY = "6Lcfe1saAAAAAHfuL43m8eAZnOWYcp-xtwO7CDiJ";

const PayPolicy = (props) => {
    let history = useHistory(); /* eslint-disable-line */
    const location = useLocation();
    const isSwann = props?.isSwann;
    const [clientNameError, setClientNameError] = useState("");
    const [amountToPayError, setAmountToPayError] = useState("");
    const [clientNumberError, setClientNumberError] = useState("");
    const [policyNumberError, setPolicyNumberError] = useState("");
    const [registrationNumberError, setRegistrationNumberError] = useState("");
    const [clientName, setClaimNumber] = useState("");
    const [amountToPay, setAmountToPay] = useState("");
    const [clientNumber, setClientNumber] = useState("");
    const [policyNumber, setPolicyNumber] = useState("");
    const [policyNumberSuffix, setPolicyNumberSuffix] = useState("-vcy");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const chromaContext = useContext(ChromaContext);
    const [hasApiError, setHasApiError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const chromaReactVersion = require('./../../@iag-packages/chroma-react/package.json').version;
    const chromaCoreDependency = require('./../../@iag-packages/chroma-react/package.json').chromaCoreDependency;
    const chromaAssetsDependency = require('./../../@iag-packages/chroma-react/package.json').chromaAssetsDependency;

    useLayoutEffect(() => {
        Analytics.init({
            chromaReactVersion,
            chromaCoreDependency,
            chromaAssetsDependency,
            Form: "PolicyPay",
        });
    }, []);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
            removeIFrame();
        };

        // load the script by passing the URL
        loadScriptByURL(
            "recaptcha-key",
            `https://www.google.com/recaptcha/api.js?render=${RECUPTURE_SITE_KEY}`
        );

        chromaContext.contentPaddingTop(false);
    }, [chromaContext]);

    function removeIFrame() {
        var frame = document.getElementById("windcave");
        if (frame) {
            var frameDoc = frame.contentDocument || frame.contentWindow.document;
            frameDoc.removeChild(frameDoc.documentElement);
        }
    }

    async function validate() {
        setHasApiError(false);

        if (clientName === "") {
            setClientNameError("Client Name is empty");
        } else {
            setClientNameError("");
        }
        if (amountToPay === "") {
            setAmountToPayError("Amount to Pay is empty");
        } else {
            setAmountToPayError("");
        }
        if (clientNumber === "") {
            setClientNumberError("Client Number is empty");
        } else {
            setClientNumberError("");
        }

        if (policyNumber === "") {
            setPolicyNumberError("Policy Number is empty");
        } else {
            setPolicyNumberError("");
        }

        if (clientName !== "" && amountToPay !== "") {
            setIsLoading(true);

            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(RECUPTURE_SITE_KEY, { action: "submit" })
                    .then(async (token) => {

                        // assuming this is when we proceed with the payment
                        try {
                            var url = await paymentApi.getUrl(
                                clientName,
                                amountToPay,
                                clientNumber,
                                isSwann ? (policyNumber + policyNumberSuffix) : policyNumber,
                                registrationNumber,
                                "/success-redirect",
                                "/failure-redirect",
                                props.brand
                            );

                            history.push({
                                pathname: "/creditcard",
                                state: { windcave: url },
                            });
                        } catch (error) {
                            //Redirect to error page but in the meantime,
                            //let's redirect the customer to an empty creditCard page

                            setHasApiError(true);
                            setIsLoading(false);
                        };
                    });
            });
        }
    }

    const registrationNumberChange = (event) => {
        setRegistrationNumber(event.target.value);
    };

    const policyNumberChange = (event) => {
        setPolicyNumber(event.target.value);
    };

    const policyNumberSuffixChange = (event) => {
        setPolicyNumberSuffix(event.target.value);
    };

    function setLabel(label) {
        return isSwann ? label + "*" : label;
    }

    return (
        <Main>
            <Container>
                <Row>
                    <Column xs={12} sm={6}>
                        {hasApiError &&
                            <Alert error heading="Error">
                                <Text> Sorry, something went wrong. Please try again or call us on 0800 800 800
                                    to make your payment. We apologize for any inconvenience.</Text>
                            </Alert>
                        }
                        <FormGroup
                            id="client-name"
                            label={setLabel("Client Name")}
                            error={clientNameError}
                            margin={{ bottom: 0 }}
                        >
                            <Input
                                error={clientNameError !== ""}
                                value={clientName}
                                onChange={(e) => setClaimNumber(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup
                            id="amountToPay"
                            label={setLabel("Amount to Pay")}
                            error={amountToPayError}
                            margin={{ bottom: 0 }}
                        >
                            <Input
                                error={amountToPayError !== ""}
                                value={amountToPay}
                                maxLength={10}
                                onChange={(e) => setAmountToPay(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup
                            id="clientNumber"
                            label={setLabel("Client Number")}
                            error={clientNumberError}
                            margin={{ bottom: 0 }}
                        >
                            <Input
                                error={clientNumberError !== ""}
                                value={clientNumber}
                                maxLength={10}
                                onChange={(e) => setClientNumber(e.target.value)}
                            />
                        </FormGroup>

                        {!isSwann && (
                            <LanternPolicyNumber
                                policyNumberError={policyNumberError}
                                policyNumber={policyNumber}
                                policyNumberChange={policyNumberChange}
                            ></LanternPolicyNumber>
                        )}

                        {isSwann && (
                            <SwannPolicyNumber
                                policyNumberError={policyNumberError}
                                policyNumber={policyNumber}
                                policyNumberChange={policyNumberChange}
                                policyNumberSuffix={policyNumberSuffix}
                                policyNumberSuffixChange={policyNumberSuffixChange}
                            ></SwannPolicyNumber>
                        )}

                        {isSwann && (
                            <RegistrationNumber
                                registrationNumber={registrationNumber}
                                registrationNumberError={registrationNumberError}
                                registrationNumberChange={registrationNumberChange}
                            />
                        )}
                        <br></br>
                        <ButtonGroup>
                            <Button primary id="pay policy" onClick={() => validate()} loading={isLoading}>
                                Pay My Policy
                            </Button>
                        </ButtonGroup>
                    </Column>
                </Row>
            </Container>
        </Main>
    );
};

export default PayPolicy;

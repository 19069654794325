"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var brandConfig = {
  nrma: {
    primary: '#00338d'
  },
  cgu: {
    primary: '#008833'
  },
  coles: {
    primary: '#E01A22'
  },
  sgic: {
    primary: '#d43900'
  },
  sgio: {
    primary: '#d43900'
  },
  bendigo: {
    primary: '#b20838'
  },
  racv: {
    primary: '#1f5aa5'
  },
  wfi: {
    primary: '#00665b'
  },
  ami: {
    primary: '#008375'
  },
  state: {
    primary: '#242C2E'
  },
  'safer-journeys': {
    primary: '#0216BE'
  },
  chroma: {
    primary: '#5D03EA'
  },
  monochroma: {
    primary: '#000'
  }
};
var _default = brandConfig;
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var columnSize;
var columnOrder;

var setColumnSize = function setColumnSize(_xs, _sm, _md, _lg, _xl) {
  var xs = _xs ? "col-".concat(_xs) : 'col';
  var sm = _sm ? "col-sm-".concat(_sm) : '';
  var md = _md ? "col-md-".concat(_md) : '';
  var lg = _lg ? "col-lg-".concat(_lg) : '';
  var xl = _xl ? "col-xl-".concat(_xl) : '';
  columnSize = (0, _classnames.default)(xs, sm, md, lg, xl);
  return columnSize;
};

var setColumnOrder = function setColumnOrder(_xsOrder, _smOrder, _mdOrder, _lgOrder, _xlOrder) {
  var xsOrder = _xsOrder ? "order-".concat(_xsOrder, " order-sm-0") : '';
  var smOrder = _smOrder ? "order-sm-".concat(_smOrder) : '';
  var mdOrder = _mdOrder ? "order-md-".concat(_mdOrder) : '';
  var lgOrder = _lgOrder ? "order-lg-".concat(_lgOrder) : '';
  var xlOrder = _xlOrder ? "order-xl-".concat(_xlOrder) : '';
  columnOrder = (0, _classnames.default)(xsOrder, smOrder, mdOrder, lgOrder, xlOrder);
  return columnOrder;
};

var setOffset = function setOffset(_xsOffset, _smOffset, _mdOffset, _lgOffset, _xlOffset) {
  var xsOffset = _xsOffset ? "offset-".concat(_xsOffset) : '';
  var smOffset = _smOffset ? "offset-sm-".concat(_smOffset) : '';
  var mdOffset = _mdOffset ? "offset-md-".concat(_mdOffset) : '';
  var lgOffset = _lgOffset ? "offset-lg-".concat(_lgOffset) : '';
  var xlOffset = _xlOffset ? "offset-xl-".concat(_xlOffset) : '';
  return (0, _classnames.default)(xsOffset, smOffset, mdOffset, lgOffset, xlOffset);
};

var setColumnClass = function setColumnClass(breakpoint) {
  var columnClass = (0, _classnames.default)({
    'col-sm': breakpoint === 'sm',
    'col-md': breakpoint === 'md',
    'col-lg': breakpoint === 'lg',
    'col-xl': breakpoint === 'xl'
  });
  return columnClass || '';
};

var setHiddenClass = function setHiddenClass(breakpoint) {
  var hiddenClass = (0, _classnames.default)({
    'u-display--none u-display-sm--block': breakpoint === 'xs',
    'u-display--block u-display-sm--none': breakpoint === 'sm',
    'u-display--block u-display-md--none': breakpoint === 'md',
    'u-display--block u-display-lg--none': breakpoint === 'lg'
  });
  return hiddenClass || '';
};

var setVisibilityClass = function setVisibilityClass(breakpoint) {
  var visibilityClass = (0, _classnames.default)({
    'u-display--block u-display-sm--none': breakpoint === 'xs',
    'u-display--none u-display-sm--block': breakpoint === 'sm',
    'u-display--none u-display-md--block': breakpoint === 'md',
    'u-display--none u-display-lg--block': breakpoint === 'lg'
  });
  return visibilityClass || '';
};

var _default = {
  setColumnSize: setColumnSize,
  setColumnOrder: setColumnOrder,
  setOffset: setOffset,
  setColumnClass: setColumnClass,
  setHiddenClass: setHiddenClass,
  setVisibilityClass: setVisibilityClass
};
exports.default = _default;
import React, { useEffect, useCallback} from "react";
import {useLocation} from 'react-router-dom';
import Payment from '../../components/Payment'
import { useHistory } from "react-router-dom";

const CreditCard = (props) => {
    let location = useLocation();
    const paymentUrl = location.state.windcave;
    const history = useHistory();
    const updateMessage = useCallback(() => {
      function receiveMessage(event: { data: string; origin: string }) {
        if (event.origin !== window.location.origin) {
          return;
        }
        if (
          event.data === 'undefined' ||
          event.data === null ||
          typeof event.data !== 'string'
        ) {
          return;
        }

        if (event.data.startsWith('FailedPayment')) {
          history.push(`/failure?brand=${props.brand}`);
        }

        if (event.data.startsWith('SuccessfulPayment')) {
          history.push(`/success?brand=${props.brand}`);
        }
      }

      window.addEventListener('message', receiveMessage, false);
      return () => {
        window.removeEventListener('message', receiveMessage, false);
      };
    }, [history]);

    useEffect(() => {
      updateMessage();
    }, [updateMessage]);

      return (
      <div>
          {paymentUrl.length > 0 &&
              <Payment src={paymentUrl}></Payment>
          }
      </div>
      )
  }

  export default CreditCard;
import React, { Fragment } from 'react';


const SuccessRedirect = (props) => {

  window.top.postMessage('SuccessfulPayment', window.location.origin);

  return (
    <Fragment>
    </Fragment>
  )
};

export default SuccessRedirect;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripSpaces = exports.setTextColor = void 0;
var textColor;

var setTextColor = function setTextColor(color) {
  switch (color) {
    case 'disabled':
      textColor = 'u-text--grey-darken-35';
      break;

    case 'success':
      textColor = 'u-text--green';
      break;

    case 'info':
      textColor = 'u-text--blue';
      break;

    case 'warning':
      textColor = 'u-text--orange';
      break;

    case 'error':
      textColor = 'u-text--red';
      break;

    default:
      textColor = '';
  }

  return textColor;
};

exports.setTextColor = setTextColor;

var stripSpaces = function stripSpaces(string) {
  return string.replace(/\s/g, '');
};

exports.stripSpaces = stripSpaces;
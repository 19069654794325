"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * Listen for user tabbing, if true add 'user-is-tabbing' class to body for focus states
 */
function handleTabbing(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
  }
}

function handleMousedown() {
  document.body.classList.remove('user-is-tabbing');
}

function initTabbingListener() {
  window.addEventListener('keydown', handleTabbing);
  window.addEventListener('mousedown', handleMousedown);
}

var _default = initTabbingListener;
exports.default = _default;
export const Brands = {
  swann: 'swann',
  lantern: 'lantern',
};

export function getBrand(queryString) {
  var domain = window.location.href;
  var brand = null;
  if (domain.toLowerCase().includes(Brands.swann)) {
    brand = Brands.swann;
  } else if (domain.toLowerCase().includes(Brands.lantern)) {
    brand = Brands.lantern;
  }
  if (brand === null) {
    brand = queryString.get('brand');
  }
  return brand;
}
